import {CloudName, CloudType, Environment} from '@q9elements/ui-core';

export const environment: Environment = {
    version: require('../../package.json').version,
    API_ENDPOINT: 'https://api.govcloud.elements.cloud/api/v1', 
    API_STATIC_FILE_UPLOAD: 'https://upload.govcloud.elements.cloud:1443/api',
    APPNAME: 'HD-APP',
    CLOUD_NAME: CloudName.ELEMENTS,
    CLOUD_TYPE: CloudType.PRIVATE,
    DATA_STRUCTURE_URL: 'https://datatable.govcloud.elements.cloud',
    DIAGRAM_URL: 'https://diagram.govcloud.elements.cloud',
    DOMAIN: 'govcloud.elements.cloud',
    ENTERPRISE_URL: 'https://corp.govcloud.elements.cloud',
    ENV: 'govcloud',
    GDPR_SUPPORT_LINK: 'https://support.elements.cloud/en/articles/1221094-enable-data-privacy-gdpr-compliance-for-a-ref-model',
    IMPORT_MAP_API: 'https://gateway.govcloud.elements.cloud/map-import/api/v1',
    IMPORTS_API: 'https://gateway.govcloud.elements.cloud/import/api/v1',
    INTERCOM_APP_ID: 'c2s7te3m',
    MAIN_APP_URL: 'https://app.govcloud.elements.cloud',
    MAP_MANAGEMENT_APP: 'https://mapmgt.govcloud.elements.cloud',
    NO_IMAGE_URL: '../../assets/images/no_image.png',
    PARTNER_APP: 'https://partnermgt.govcloud.elements.cloud', 
    PREVIEW_HOLDER: '/assets/images/map_placeholder.png', 
    production: true, 
    RAVEN_DSN: 'https://e19710c37630430c9c1178e09a054561@o45875.ingest.sentry.io/4504673073037312', 
    RAVEN_ID: '9e76fe14aaba4a31846fe607572f9eb1',
    REF_MODEL_APP: 'https://refmodel.govcloud.elements.cloud',
    RELEASE_URL: 'https://releasemgt.govcloud.elements.cloud',
    REMOTE_UNLOCK_TTL: 3600000,
    REPORTS_API_ENDPOINT: 'https://gateway.govcloud.elements.cloud/report/api/v1',
    REPORTS_APP: 'https://report.govcloud.elements.cloud',
    SALESFORCE_CLIENT_ID: '3MVG9Rd3qC6oMalVszZtK2Usdv0kmAXCXN7jNx.bxrXbMIR46h1q6AsNtluiXy9tUjXwhgDczWNbzABIUSr42',
    SALESFORCE_REDIRECT_URL: 'https://app.govcloud.elements.cloud/auth/callback',
    SALESFORCE_URL_TEST: 'https://test.salesforce.com/services/oauth2/authorize',
    SALESFORCE_URL: 'https://login.salesforce.com/services/oauth2/authorize',
    SECURE_COOKIES: true,
    SYS_ADMIN_APP: 'https://sysadmin.govcloud.elements.cloud',
    TEAM_APP: 'https://spacemgt.govcloud.elements.cloud',
    TOASTR_TIMEOUT: 5000,
    UNLOCK_TTL: 180000,
    fbConfig: {
        apiKey:'AIzaSyAJc0MTZVcugZLQ8D8_V2qrgVV5Ojtt01s',
        authDomain:'elements-prod-us-govcloud.firebaseapp.com',
        databaseURL: 'https://elements-prod-us-govcloud-default-rtdb.firebaseio.com/',
        projectId: 'elements-prod-us-govcloud',
        appId: '1:308130691497:web:e679a6f5a0ec63fd89acb8'
    }
};
